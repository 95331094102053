@font-face {
  font-family: 'PixelFont';
  src: url(./assets/Daydream.ttf) format('truetype');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

body, button {
  margin: 0;
  font-family:  'Inter', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f1f6f0;
  border: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.pixelFont {
  font-family:  'PixelFont';
}

button {
  cursor: pointer;
}

.gifFrames {
  position: relative;
  width: 384px;
  height: 384px;
}

.gifFrames img {
  position: absolute;
  top:0;
  left:0;
}

.gifFrames > div {
  position: absolute;
  top:0;
  left:0;
  width: 384px;
  height: 384px;
}

.unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}